import { Routes, Route } from "react-router-dom";
import "./App.css";
import FullPaymentAdvanced from "./components/advanced/FullPayment";
import FullPaymentAdvancedNaira from "./components/advanced/FullPaymentNaira";
import InstallmentPaymentAdvanced from "./components/advanced/Installment";
import FullAdvancedInstalNaira from "./components/advanced/InstallmentNaira";
import FullPaymentBeginnerNaira from "./components/beginner/FullPaymentBeginnerNaira";
import DiscountBeginner from "./components/discount/DiscountPayment";
import BeginnerFull from "./routes/beginner_full/BeginnerFull";
import BeginnerInstalment from "./routes/beginner_install/BeginnerInstalment";
import IntermediateFull from "./routes/intermediate_full/IntermediateFull";
import IntermediateInstalment from "./routes/intermediate_install/IntermediateInstalment";
import InstalmentPaymentBeginnerNaira from "./components/beginner/InstalmentPaymentBeginnerNaira";
import FullPaymentIntermediateNaira from "./components/intermediate/FullPaymentIntermediateNaira";
import InstalmentPaymentIntermediateNaira from "./components/intermediate/InstalmentPaymentIntermediateNaira";
import DiscountPaymentNaira from "./components/discount/DiscountPaymentNaira";
import MainPage from "./components/main_page/MainPage";
import DiscountIntermediate from "./components/discount/DiscountIntermediate";
import DiscountAdvanced from "./components/discount/DiscountAdvanced";
import DiscountIntermediateNaira from "./components/discount/DiscountIntermediateNaira";
import DiscountAdvancedNaira from "./components/discount/DiscountAdvancedNaira";
import StandalonePaymentARE from "./components/standalone_payment/StandalonePayment.jsx";
import StandalonePaymentAMM from "./components/standalone_payment_amm/StandalonePayment_amm";
function App() {
  return (
    <>
      <Routes>
        <Route path="*" element={"404 not found"} />
        <Route path="/" element={<MainPage />} />
        <Route path="/beginner-full" element={<BeginnerFull />} />
        <Route path="/beginner-instalment" element={<BeginnerInstalment />} />
        <Route
          path="/beginner-full-naira"
          element={<FullPaymentBeginnerNaira />}
        />
        <Route
          path="/beginner-instalment-naira"
          element={<InstalmentPaymentBeginnerNaira />}
        />
        <Route path="/intermediate-full" element={<IntermediateFull />} />
        <Route
          path="/intermediate-instalment"
          element={<IntermediateInstalment />}
        />
        <Route
          path="/intermediate-full-naira"
          element={<FullPaymentIntermediateNaira />}
        />
        <Route
          path="/intermediate-instalment-naira"
          element={<InstalmentPaymentIntermediateNaira />}
        />
        <Route path="/advanced-full" element={<FullPaymentAdvanced />} />
        <Route
          path="/advanced-full-naira"
          element={<FullPaymentAdvancedNaira />}
        />
        <Route
          path="/advanced-instalment-naira"
          element={<FullAdvancedInstalNaira />}
        />
        <Route
          path="/advanced-instalment"
          element={<InstallmentPaymentAdvanced />}
        />
        <Route path="/discount" element={<DiscountBeginner />} />
        <Route path="/discount-naira" element={<DiscountPaymentNaira />} />
        <Route
          path="/discount-intermediate"
          element={<DiscountIntermediate />}
        />
        <Route path="/discount-advanced" element={<DiscountAdvanced />} />
        <Route
          path="/discount-intermediate-naira"
          element={<DiscountIntermediateNaira />}
        />
        <Route
          path="/discount-advanced-naira"
          element={<DiscountAdvancedNaira />}
        />
        <Route
          path="/discount-advanced-naira"
          element={<DiscountAdvancedNaira />}
        />
        <Route path="/standalone-are" element={<StandalonePaymentARE />} />
        <Route path="/standalone-amm" element={<StandalonePaymentAMM />} />
      </Routes>
    </>
  );
}

export default App;
