import React, { useState } from "react";
import kmaLogo from "../../assets/images/kma-logo.png";
import { Link } from "react-router-dom";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

import "./FullPaymentBeginner.css";

const FullPaymentBeginner = () => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");

  const amount = "800";
  const redirect = "https://www.krizbeatzmusicacademy.com/#/welcome";

  const config = {
    public_key: "FLWPUBK-06c10d7f9387be7f9f663f44fd4f3002-X",
    tx_ref: Date.now(),
    amount: amount,
    currency: "USD",
    payment_options:
      "card, mobilemoneyghana, ussd, account, banktransfer, barter, credit, nqr, mpesa",
    redirect_url: redirect,
    customer: {
      email: email,
      phone_number: phone,
      first_name: firstName,
      last_name: lastName,
    },
    customizations: {
      title: "Beginner Level",
      description: "Payment for the Beginner Level",
      logo: "https://www.linkpicture.com/q/kma-logo_1.png",
    },
  };

  const handleFlutterPayment = useFlutterwave(config);

  return (
    <>
      <div className="custom-container">
        <img
          src={kmaLogo}
          alt="Krizbeatz Music Academy Logo"
          className="kma-logo"
        />
        <p className="mt-3">
          This is the Beginner Level Full payment.
          {/* <Link to="/beginner-instalment"> Choose to pay in Instalment.</Link> */}
        </p>
        <p>
          To pay in other currencies,{" "}
          <a href="mailto:contact@krizbeatzmusicacademy.com">Contact us now.</a>
        </p>
        <div className="payment-container">
          <div className="title-price">
            <p>Beginner</p>
            <p>USD{amount}</p>
          </div>
          <div className="payment-form-container">
            <input
              type="email"
              name="email"
              placeholder="Email Address"
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <input
              type="text"
              name="fname"
              placeholder="First Name"
              onChange={(e) => setFirstName(e.target.value)}
              required
            />
            <input
              type="text"
              name="lname"
              placeholder="Last Name"
              onChange={(e) => setLastName(e.target.value)}
              required
            />
            <PhoneInput
              international
              countryCallingCodeEditable={false}
              defaultCountry="NG"
              value={phone}
              type="tel"
              name="phone"
              placeholder="Phone number"
              onChange={setPhone}
              required
            />

            <button
              className="button primary-btn pay-btn"
              onClick={() =>
                handleFlutterPayment({
                  callback: (response) => {
                    console.log(response);
                    closePaymentModal();
                  },
                  onClose: () => {},
                })
              }
            >
              Pay now
            </button>
          </div>
        </div>
        <p>
          Note: Fill in all details before you proceed, payment will not be
          verified if you omit any of the form field.
        </p>
      </div>
    </>
  );
};

export default FullPaymentBeginner;
