import React from 'react'
import InstallmentPaymentIntermediate from '../../components/intermediate/InstallmentPaymentIntermediate'

const IntermediateInstalment = () => {
  return (
    <>
      <InstallmentPaymentIntermediate />
    </>
  )
}

export default IntermediateInstalment
