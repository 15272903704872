import React from 'react'
import InstallmentPaymentBeginner from '../../components/beginner/InstallmentPaymentBeginner'

const BeginnerInstalment = () => {
  return (
    <>
      <InstallmentPaymentBeginner />
    </>
  )
}

export default BeginnerInstalment
