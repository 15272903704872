import React from 'react'
import FullPaymentIntermediate from '../../components/intermediate/FullPaymentIntermediate'

const IntermediateFull = () => {
  return (
    <>
      <FullPaymentIntermediate />
    </>
  )
}

export default IntermediateFull
