import React from "react";
import kmaLogo from "../../assets/images/kma-logo.png";

import { Link } from "react-router-dom";

import "./MainPage.css";

const MainPage = () => {
  return (
    <>
      <div className="custom-container">
        <img
          src={kmaLogo}
          alt="Krizbeatz Music Academy Logo"
          className="kma-logo"
        />
        <p className="mt-4">
          Choose which Level and Currency you want to pay in.
        </p>
        <div className="main-container">
          <div className="beginner flex-card">
            <p>Beginner</p>
            <div className="action-pay-btns">
              {/* <Link to='/beginner-full-naira'>
                {' '}
                <button className='button primary-btn naira-pay'>NGN</button>
              </Link> */}
              <Link to="/beginner-full">
                <button className="button primary-btn usd-pay">USD</button>
              </Link>
            </div>
          </div>
          <div className="divider my-4"></div>
          <div className="intermediate flex-card">
            <p>Intermediate</p>
            <div className="action-pay-btns">
              <Link to="/intermediate-full-naira">
                {" "}
                <button className="button primary-btn naira-pay">NGN</button>
              </Link>
              <Link to="/intermediate-full">
                <button className="button primary-btn usd-pay">USD</button>
              </Link>
            </div>
          </div>
          <div className="divider my-4"></div>
          <div className="advanced flex-card">
            <p>Advanced</p>
            <div className="action-pay-btns">
              <Link to="/advanced-full-naira">
                {" "}
                <button className="button primary-btn naira-pay">NGN</button>
              </Link>
              <Link to="/advanced-full">
                <button className="button primary-btn usd-pay">USD</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainPage;
