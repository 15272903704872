import React from 'react'
import FullPaymentBeginner from '../../components/beginner/FullPaymentBeginner'

const BeginnerFull = () => {
  return (
    <>
      <FullPaymentBeginner />
    </>
  )
}

export default BeginnerFull
