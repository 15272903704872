import React, { useState } from 'react'
import { PaystackButton } from 'react-paystack'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Link } from 'react-router-dom'
import kmaLogo from '../../assets/images/kma-logo.png'
import PhoneInput from 'react-phone-number-input'

const FullPaymentIntermediateNaira = () => {
  const publicKey = 'pk_live_83faa5926a3bfc02e6e33d33560ae6b7d336f630'
  const amount = 60000000 // Set in kobo!
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')

  const resetForm = () => {
    setEmail('')
    setFirstName('')
    setLastName('')
    setPhone('')
  }

  const componentProps = {
    email,
    amount,
    metadata: {
      firstName,
      lastName,
      phone
    },
    preventDefault: true,
    publicKey,
    text: 'Pay now',
    onSuccess: ({ reference }) => {
      toast.success('Payment Successfull, Check your email for details')
      resetForm()
    },
    onclose: () =>
      toast.error('This class would really be great, try payment again!!!')
  }

  return (
    <>
      <div className='custom-container'>
        <img
          src={kmaLogo}
          alt='Krizbeatz Music Academy Logo'
          className='kma-logo'
        />
        <p className='mt-3'>
          This is the Intermediate Level Full payment.
          <Link to='/intermediate-instalment-naira'> Choose to pay in Installment</Link>
        </p>
        <p>To pay in other currencies, <a href="mailto:contact@krizbeatz.org">Contact us now.</a></p>
        <div className='payment-container'>
          <div className='title-price'>
            <p>Intermediate</p>
            <p>NGN{amount / 100}</p>
          </div>
          <div className='payment-form-container'>
            <input
              type='email'
              name='email'
              placeholder='Email Address'
              onChange={e => setEmail(e.target.value)}
              required
            />
            <input
              type='text'
              name='fname'
              placeholder='First Name'
              onChange={e => setFirstName(e.target.value)}
              required
            />
            <input
              type='text'
              name='lname'
              placeholder='Last Name'
              onChange={e => setLastName(e.target.value)}
              required
            />
            <PhoneInput
              international
              countryCallingCodeEditable={false}
              defaultCountry='NG'
              value={phone}
              type='tel'
              name='phone'
              placeholder='Phone number'
              onChange={setPhone}
              required
            />

            <PaystackButton
              className='button primary-btn pay-btn'
              {...componentProps}
            />
          </div>
        </div>
        <p>
          Note: Fill in all details before you proceed, payment will not be
          verified if you omit any of the form field.
        </p>
      </div>
    </>
  )
}

export default FullPaymentIntermediateNaira
